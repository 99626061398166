import React, { FC, useState, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import t from "../../localization/en/translation.json";
import {
  AppRadioButton,
  AppButton,
  AppModal,
  AppIcon,
  MotifAppInput,
  AppAlert,
} from "../../theme";
import {
  crossCircle,
  info,
  check,
  file,
  userImage,
  arrowDownToLine,
  cross,
  magnifyingGlass,
  circleCheck,
  exclamation,
} from "../../theme/icons";
import cloudUpload from "../../assets/images/icons/cloud-upload.svg";
import checkCircle from "../../assets/images/icons/check_circle.svg";
import { IUser } from "../../models/user";
import "./manageUserModal.scss";
import StepperIndicator from "./../stepperIndicator/stepperIndicator";
interface IProps {
  isOpen: boolean;
  handleAddUserClick: Function;
  searchUser: (email: string) => void;
  manageSearchedUserData: IUser;
  apiSuccess: boolean;
  handleAddUserToGrid: Function;
  isUserAlreadyExist: boolean;
  bulkImportTemplatedownload: Function;
  bulkImportTemplateSuccess: boolean;
  bulkImportTemplateFailure: boolean;
  bulkUserUploadRequest: Function;
  bulkUserUploadErrors: any;
  bulkImportSave: Function;
  bulkImportSaveData: boolean;
}

enum EUserAddType {
  Single = "single",
  Bulk = "bulk",
}

const ManageUserModal: FC<IProps> = ({
  isOpen,
  handleAddUserClick,
  searchUser,
  manageSearchedUserData,
  apiSuccess,
  handleAddUserToGrid,
  isUserAlreadyExist,
  bulkImportTemplatedownload,
  bulkImportTemplateSuccess,
  bulkImportTemplateFailure,
  bulkUserUploadRequest,
  bulkUserUploadErrors,
  bulkImportSave,
  bulkImportSaveData,
}) => {
  const [emailValue, setEmailValue] = useState("");
  const [isValid, setValid] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(EUserAddType.Single);
  const [bulkUserCurrentState, setBulkUserCurrentState] = useState(1);
  const [disabledDownload, disabledDownloadButton] = useState(false);
  const [fileName, updateFileName] = useState("");

  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((acceptedFile) => {
      updateFileName(acceptedFile.name);
      formData.append("file", acceptedFile);
    });

    if (acceptedFiles.length > 0) {
      bulkUserUploadRequest(formData);
      setBulkUserCurrentState(2);
    }
    // eslint-disable-next-line
  }, []);

  const onDragOver = (e) => {
    const event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };

  const onDragEnter = (e) => {
    const event = e as Event;
    event.stopPropagation();
  };

  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: ".xlsm",
    maxFiles: 1,
    maxSize: 73400320,
    onDrop,
    onDragOver,
    onDragEnter,
    noDragEventsBubbling: true,
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <spam>{t.admin_apps_rejected_apps_msg}</spam>
  ));

  useEffect(() => {
    disabledDownloadButton(false);
    if (bulkImportSaveData) {
      setBulkUserCurrentState(3);
    }
  }, [
    bulkImportTemplateSuccess,
    bulkImportTemplateFailure,
    bulkImportSaveData,
  ]);

  useEffect(() => {
    if (isOpen) {
      setBulkUserCurrentState(1);
    }
  }, [isOpen]);

  const validateEmail = (email) => {
    setEmailValue(email);
    // eslint-disable-next-line
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValid(re.test(String(email).toLowerCase()));
  };

  const onConfirm = () => {
    if (selectedUserType === EUserAddType.Single) {
      handleAddUserToGrid(manageSearchedUserData);
      setEmailValue("");
      validateEmail("");
    } else if (selectedUserType === EUserAddType.Bulk) {
      bulkImportSave();
    }
  };

  const onClose = () => {
    setEmailValue("");
    setBulkUserCurrentState(1);
    handleAddUserClick(false);
  };

  const downloadTemplate = () => {
    disabledDownloadButton(true);
    bulkImportTemplatedownload();
  };

  const removeFile = () => {
    setBulkUserCurrentState(1);
  };

  const reUploadFile = () => {
    setBulkUserCurrentState(1);
  };

  const setSelectedUser = (value) => {
    setSelectedUserType(value);
    if (value === EUserAddType.Single) {
      setBulkUserCurrentState(1);
    } else if (value === EUserAddType.Bulk) {
      handleAddUserClick(true);
      setEmailValue("");
    }
  };
  const isUserNotFound =
    apiSuccess && Object.keys(manageSearchedUserData).length === 0;
  const stepData = [
    { step_text: null, title_text: t.manage_user_bulk_users_upload },
    { step_text: null, title_text: t.manage_user_bulk_users_validate },
    { step_text: null, title_text: t.manage_user_bulk_users_save },
  ];
  return (
    <AppModal
      modalClass="manage-user-add-user"
      showModal={isOpen}
      additionalBtns={
        (bulkUserCurrentState === 2 &&
          bulkUserUploadErrors &&
          !bulkUserUploadErrors?.data?.status && (
            <div className="filename-action reupload" onClick={reUploadFile}>
              {t.reupload}
            </div>
          )) ||
        null
      }
      onModalClose={() => onClose()}
      onConfirm={() => onConfirm()}
      cancelBtnText={
        bulkImportSaveData &&
        selectedUserType === EUserAddType.Bulk &&
        bulkUserCurrentState === 3
          ? t.Close
          : t.cancel
      }
      confirmBtnText={
        selectedUserType === EUserAddType.Single
          ? t.manage_user_add
          : (bulkUserUploadErrors &&
              bulkUserUploadErrors?.data?.status &&
              t.manage_user_bulk_users_next) ||
            ""
      }
      title={t.manage_user_add_new_user}
      disabledValue={
        selectedUserType === EUserAddType.Single
          ? Object.keys(manageSearchedUserData).length === 0 ||
            isUserAlreadyExist
          : !bulkUserUploadErrors || !bulkUserUploadErrors?.data?.status
      }
      hideConfirmButton={
        (selectedUserType === EUserAddType.Bulk &&
          (!bulkUserUploadErrors ||
            !bulkUserUploadErrors?.data?.status ||
            bulkUserCurrentState === 1 ||
            bulkUserCurrentState === 3)) ||
        false
      }
    >
      <div className="radio-grp">
        <AppRadioButton
          name="userType"
          id="userTypeSingle"
          value={EUserAddType.Single}
          checked={selectedUserType === EUserAddType.Single}
          onChange={(event) => setSelectedUser(event.target.value)}
        >
          {t.manage_user_single_user}
        </AppRadioButton>
        <AppRadioButton
          name="userType"
          id="userTypeBulk"
          value={EUserAddType.Bulk}
          checked={selectedUserType === EUserAddType.Bulk}
          onChange={(event) => setSelectedUser(event.target.value)}
        >
          {t.manage_user_bulk_users}
        </AppRadioButton>
      </div>
      {selectedUserType === EUserAddType.Single && (
        <>
          {isUserAlreadyExist && (
            <AppAlert severity="error">{t.manage_user_already_exist}</AppAlert>
          )}
          {isUserNotFound && (
            <AppAlert severity="error">
              {t.manage_user_not_found} &nbsp;
              <a
                className="my-ey-register"
                href={t.manage_user_registration_link}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t.my_ey_Link_text}
              </a>
            </AppAlert>
          )}
          <div className="manage-user-modal">
            <div className="form-field manage-search">
              <div className="project-form-field manage-search-item">
                <MotifAppInput
                  name={"email"}
                  maxLength={64}
                  value={emailValue}
                  onChange={(event) => validateEmail(event.target.value)}
                  placeholder={t.manage_user_enter_new_email}
                  startAdornment={
                    <AppIcon
                      icon={magnifyingGlass}
                      className="search-box-icon"
                    />
                  }
                />
              </div>
              <div className="project-form-field padding-left">
                <AppButton
                  type="button"
                  size="medium"
                  className="search-btn"
                  onClick={() => searchUser(emailValue)}
                  disabled={!isValid}
                >
                  {t.manage_user_search}
                </AppButton>
              </div>
            </div>

            <div className="form-field">
              <div className="form-field-width">
                <div className="form-field">
                  <div className="project-form-field form-field-margin">
                    <MotifAppInput
                      className="readonly"
                      maxLength={25}
                      label={t.manage_user_first_name}
                      value={manageSearchedUserData.givenName || "Enter value"}
                      placeholder={t.manage_user_first_name}
                      disabled={true}
                      InputLabelProps={{
                        shrink: false,
                      }}
                    />
                  </div>
                  <div className="project-form-field form-field-margin rt-mrgn">
                    <MotifAppInput
                      className="readonly"
                      name={t.manage_user_last_name}
                      maxLength={25}
                      label={t.manage_user_last_name}
                      value={manageSearchedUserData.surname || "Enter value"}
                      placeholder={t.manage_user_last_name}
                      disabled={true}
                      InputLabelProps={{
                        shrink: false,
                      }}
                    />
                  </div>
                </div>
                <div className="project-form-field">
                  <MotifAppInput
                    className="readonly manage-search-item-margin"
                    label={t.manage_user_location}
                    maxLength={25}
                    value={
                      manageSearchedUserData.officeLocation || "Enter value"
                    }
                    placeholder={t.manage_user_location}
                    disabled={true}
                    InputLabelProps={{
                      shrink: false,
                    }}
                  />
                </div>
              </div>
              <div className="user-img-wrapper">
                <div className="project-form-field">
                  {manageSearchedUserData.photo ? (
                    <img
                      src={
                        "data:image/png;base64," + manageSearchedUserData.photo
                      }
                      className="image-cropper"
                      alt="user"
                    />
                  ) : (
                    <AppIcon className="image-cropper" icon={userImage} />
                  )}
                </div>
                {manageSearchedUserData.photo && (
                  <div className="check-tick">
                    <AppIcon icon={circleCheck} className="check-icon" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {selectedUserType === EUserAddType.Bulk && (
        <>
          <div className="stepper-container">
            <StepperIndicator
              stepIndicatorState={{ currentStep: bulkUserCurrentState }}
              stepData={stepData}
              handleStepClick={() => {}}
            />
          </div>
          {bulkUserCurrentState === 1 && (
            <>
              <div className="button-wrapper">
                <div>{t.manage_user_bulk_users_upload_file}</div>
                <AppButton
                  disabled={disabledDownload}
                  className={`download-btn ${
                    bulkImportTemplateSuccess ? "download-sucess" : ""
                  } ${bulkImportTemplateFailure ? "download-failure" : ""}`}
                  onClick={() => downloadTemplate()}
                  size="medium"
                >
                  {!bulkImportTemplateSuccess && !bulkImportTemplateFailure && (
                    <div className="download-btn-icon">
                      <AppIcon icon={arrowDownToLine} />
                      {t.manage_user_bulk_users_download}
                    </div>
                  )}
                  {bulkImportTemplateSuccess && (
                    <span className="download-icon">
                      {" "}
                      <AppIcon icon={check} />{" "}
                    </span>
                  )}
                  {bulkImportTemplateFailure && (
                    <span className="download-icon">
                      {" "}
                      <AppIcon icon={cross} />{" "}
                    </span>
                  )}
                </AppButton>
              </div>
              <div
                className={
                  fileRejectionItems.length > 0
                    ? "drag-drop-wrapper-alert"
                    : "drag-drop-wrapper"
                }
              >
                <div {...getRootProps({ className: "dropzone" })}>
                  <input {...getInputProps()} />
                  {fileRejectionItems.length > 0 ? (
                    <>
                      <AppIcon
                        className="upload-icon-size"
                        icon={exclamation}
                      />

                      <span className="upload-options">
                        {t.try_again} &nbsp;
                        <span className="upload-text">{t.click_to_upload}</span>
                      </span>
                      <span className="upload-options">
                        {fileRejectionItems}
                      </span>
                    </>
                  ) : (
                    <>
                      <img
                        src={cloudUpload}
                        className="upload-icon-size"
                        alt="Upload"
                      />

                      <span>
                        <span className="upload-text">{t.click_to_upload}</span>{" "}
                        &nbsp;
                        {t.drag_to_upload}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {bulkUserCurrentState === 2 && (
            <>
              <div className="filename-container">
                <div className="filename">
                  <AppIcon icon={file} className="file-icon" />
                  {fileName}
                </div>
                {(bulkUserUploadErrors &&
                  bulkUserUploadErrors?.data?.status && (
                    <div className="filename-action" onClick={removeFile}>
                      {t.manage_users_remove_placeholder}
                    </div>
                  )) ||
                  null}
              </div>
              <div className="upload-validation">
                {(bulkUserUploadErrors &&
                  bulkUserUploadErrors?.data?.status && (
                    <div className="validation-container success">
                      <div className="validation-icon-container">
                        <AppIcon icon={check} className="validation-icon" />
                      </div>
                      {t.bulk_no_errors}
                    </div>
                  )) ||
                  null}
                {(bulkUserUploadErrors &&
                  !bulkUserUploadErrors?.data?.status &&
                  !bulkUserUploadErrors?.error && (
                    <div className="validation-container error">
                      <AppIcon icon={info} className="validation-icon" />
                      <div>{t.bulk_error}</div>
                    </div>
                  )) ||
                  null}
                {bulkUserUploadErrors?.error && (
                  <div className="validation-container error">
                    <AppIcon icon={info} className="validation-icon" />
                    <div>{t.bulk_error_something_wrong}</div>
                  </div>
                )}
              </div>
              {(bulkUserUploadErrors &&
                !bulkUserUploadErrors?.data?.status &&
                !bulkUserUploadErrors?.error && (
                  <div className="validation-errors">
                    <div className="validation-row">
                      <div className="validation-field hdr key-field">
                        {t.bulk_field}
                      </div>
                      <div className="validation-field hdr key-cell">
                        {t.bulk_cell}
                      </div>
                      <div className="validation-field hdr key-error">
                        {t.bulk_filed_error}
                      </div>
                    </div>
                    {bulkUserUploadErrors?.data?.errors.map((error, index) => (
                      <div
                        className="validation-row row"
                        key={`${index}_${error.cell}`}
                      >
                        <div className="validation-field key-field">
                          {error.fieldAffected || "-"}
                        </div>
                        <div className="validation-field key-cell">
                          {error.cell || "-"}
                        </div>
                        <div className="validation-field key-error">
                          {error.error || "-"}
                        </div>
                      </div>
                    ))}
                  </div>
                )) ||
                null}
            </>
          )}
          {bulkUserCurrentState === 3 && (
            <>
              <div className="bulk-import-success">
                {bulkUserUploadErrors?.success ? (
                  <div className="bulk-import-check">
                    <AppIcon icon={checkCircle} />
                  </div>
                ) : (
                  <div className="bulk-import-error">
                    <AppIcon icon={crossCircle} />
                  </div>
                )}
                {bulkUserUploadErrors?.success && (
                  <div className="bulk-import-success-done">
                    {t.manage_user_bulk_users_done}
                  </div>
                )}
                {bulkUserUploadErrors?.success ? (
                  <div>{t.manage_user_bulk_users_file_imported}</div>
                ) : (
                  <div>{t.manage_user_bulk_users_file_imported_error}</div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </AppModal>
  );
};

export default ManageUserModal;
