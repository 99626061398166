import React, { useEffect, useRef, useState } from "react";
import List from "../editableGrid/list/list";
import { Box } from "@mui/material";
import t from "../../localization/en/translation.json";
import { useDispatch, useSelector } from "react-redux";
import { APP_STATE } from "../../store/reducers";
import { ACTION_NAME } from "../../containers/adminRegions/adminRegionConstants";
import { GridApi } from "ag-grid-community";
import { NOTIFICATION } from "../../store/actions/admin-portal-communication-actions";
import { renderDateCell } from "../adminWorkflows/Executions/renderDateCell";
import { RenderEmailCell } from "../adminPortalCommunications/renderEmailCell";
import { columnConfig } from "./adminPortalCommunicationColumnConfig";
import { Data, ReleaseNoteFormModal } from "./notificationModal";
import "./adminPortalNotification.scss";
import { getAdminConsoleAccess } from "../../utils/helper-utility";
import { MoreMenuCell } from "./MoreMenuCells";
import { AddNotificationButton } from "./addNotificationButton";
import { notificationStatus } from "../../utils/Constants";
import { RenderTitleCell } from "./renderTitle";

const CommonNotification = ({
  commonNotificationData,
  createNotificationAction,
  GetNotificationAction,
  updateNotificationAction,
  deleteNotificationAction,
  notificationType,
  notificationTitle,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetNotificationAction(notificationType));
  }, [dispatch, GetNotificationAction, notificationType]);
  const [openModal, setOpenModal] = useState<{} | null>(null);
  const [selectedNotificationRow, setSelectedNotificationRow] = useState<
    Data | undefined
  >(undefined);
  const isFetching = useSelector<APP_STATE, boolean>(
    ({ portalCommunications }) =>
      portalCommunications.status === notificationStatus.Fetching
  );
  const isAdminConsoleUserOnly = useSelector<APP_STATE, boolean>(
    ({ meReducer, meta }) => {
      const sspRoles = meta.userRoles;
      const accessRight = meReducer.accessRight;
      const portalRole =
        (accessRight?.portalRoles &&
          accessRight.portalRoles.length > 0 &&
          accessRight.portalRoles[0]) ||
        "";
      const _result = getAdminConsoleAccess(portalRole, sspRoles);
      return _result.portalAdminConsole && !_result.portalAdmin;
    }
  );
  const listRef = useRef<{ api: GridApi }>();

  const defaultColDef = {
    resizable: false,
    // Add other default properties that you want to apply to all columns
  };

  const doLoadingOverlay = () => {
    if (isFetching) {
      listRef.current?.api.showLoadingOverlay();
    } else {
      listRef.current?.api.hideOverlay();
    }
  }; 

  doLoadingOverlay();
  // reset selectedRow upon termination of its action that had initiated
  if (openModal === false && selectedNotificationRow != null) {
    setSelectedNotificationRow(undefined);
  }
  const onActionStart = (actionName) => {
    if (actionName === ACTION_NAME.UPDATE) {
      const rows: NOTIFICATION[] | undefined =
        listRef.current?.api?.getSelectedRows();
      if (rows) {
        const ModalProps = {
          id: rows[0].id,
          version: rows[0].title,
          content: rows[0].message,
          userTypes: rows[0].userTypes,
          status: {
            value: rows[0].status === "Active",
            startDate: new Date(rows[0].startDateTime),
            endDate: new Date(rows[0].endDateTime),
          },
        };
        setSelectedNotificationRow(ModalProps);
      }
      setOpenModal(true);
    } else if (actionName === ACTION_NAME.DELETE) {
      const rows = listRef.current?.api?.getSelectedRows();
      if (rows) {
        dispatch(deleteNotificationAction({ notificationId: rows[0].id }));
      }
    }
  };

  return (
    <>
      <span>{t.portal_communication_release_notes_tab_info}</span>
      <Box
        className={"admin-portal-communication-notification"}sx={{ paddingTop: 0 }} >
        <AddNotificationButton
          disable={isAdminConsoleUserOnly}
          onClick={() => {
            setOpenModal(true);
          }}
        />
        <Box className={`admin-portal-communication-notifications-table`}>
          <List
            columnConfig={columnConfig}  
            defaultColDef={defaultColDef}        
            onGridReady={(gridApi) => {
              listRef.current = gridApi;
              doLoadingOverlay();
            }}
            firstColumnBorderRight={true}
            withStickyScroll
           rowSelection="single"
            frameworkComponents={{
              dateCellRender: renderDateCell,
              renderEmailCell: RenderEmailCell,
              renderTitleCell:RenderTitleCell,
              moreMenuCellRenderer: () => (
                <MoreMenuCell
                  disable={isAdminConsoleUserOnly}
                  onActionStart={onActionStart}
                />
              ),
            }}
            paginate={false}
            rowData={commonNotificationData as any[]}
          />
        </Box>
      </Box>
      {openModal && (
        <ReleaseNoteFormModal
          setOpenModal={setOpenModal}
          initial={selectedNotificationRow}
          notificationType={notificationType}
          cretateNotificationAction={createNotificationAction}
          updateNotificationAction={updateNotificationAction}
          notificationTitle={notificationTitle}
        />
      )}
    </>
  );
};

export default CommonNotification;
