import React, {
  FC,
  useCallback,
  useRef,
  useState,
  useEffect,
  ReactNode,
} from "react";
import {
  AppCheckbox,
  AppFormErrorText,
  AppTooltip,
  AppIcon,
  MotifAppInput,
  MotifAppSelect,
  MotifAppTextArea,
} from "../../theme";
import {
  info,
  search,
  warning,
  cross,
  arrowRight,
  downArrow,
  exclamation,
} from "../../theme/icons";
import { ClearButton, AsyncTypeahead } from "react-bootstrap-typeahead";
import t from "../../localization/en/translation.json";
import {
  CountryLookupModel,
  lookupModel,
  RegionLookupModel,
  IServiceLines,
  SubSectorLookupModel,
  SubSubSectorLookupModel,
} from "../../containers/createNewProject/lookupModel";
import {
  IFormModel,
  IPaceFieldState,
  IApproverFieldState,
  EProjectCategory,
  EProjectCategoryKey,
} from "../../containers/createNewProject/formStateModel";
import { IProjectIcon } from "../../models/IProjectIcon";
import "./projectForm.scss";
import PricingProvisionPolicy from "./projectPricingProvisionPolicy";
import { CountryCodeFields } from "./countryCodeFieldsBlade";
import { FormFields } from "../../containers/createProject/createProject";
import { validateFormInputForSpecialChar } from "../../utils/helper-utility";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { SectorSelectComponent } from "./sectorSelectBlade";

interface IProps {
  formState: IFormModel;
  projectTypes: lookupModel[];
  sectors: lookupModel[];
  subSectors: SubSectorLookupModel[];
  subSubSectors: SubSubSectorLookupModel[];
  serviceLines: IServiceLines[];
  clientSizes: lookupModel[];
  dataLocations: lookupModel[];
  projectAreas: lookupModel[];
  projectRegions: RegionLookupModel[];
  projectCountries: CountryLookupModel[];
  projectCategories: lookupModel[];
  onFormChange: (value: unknown, field: string) => void;
  allLookupdataLoaded: number | undefined | boolean;
  noOfUsers: number;
  projectApps: string;
  paceFieldState: IPaceFieldState;
  projectIcons: IProjectIcon[];
  approverFieldState: IApproverFieldState;
  userServiceLineId: string;
}

export const validatedInputClass = "validated-input";
const FORBIDDEN_TAGS = ["#donotdelete"];
export const isDontDeleteTagPresent = (description?: string) => {
  return FORBIDDEN_TAGS.some((tag) => (description?.indexOf(tag) ?? -1) >= 0);
};

export const getOptions = (
  options: lookupModel[] = [],
  withDescription?: boolean
) => {
  return withDescription
    ? options.map((item) => ({
        value: item.id,
        label: item.name,
        descriptionLabel: item.description,
      }))
    : options.map((item) => ({ value: item.id, label: item.name }));
};

const ProjectForm: FC<IProps> = ({
  formState,
  projectTypes,
  sectors,
  subSectors,
  subSubSectors,
  serviceLines,
  projectCategories,
  clientSizes,
  dataLocations,
  onFormChange,
  allLookupdataLoaded,
  noOfUsers,
  projectApps,
  projectAreas,
  projectRegions,
  projectCountries,
  paceFieldState,
  approverFieldState,
  userServiceLineId,
}) => {
  const {
    projectName,
    projectType,
    projectClientName,
    projectSector,
    projectSubSector,
    projectSubSubSector,
    projectServiceLine,
    projectCategory,
    projectSize,
    projectLocation,
    projectDesc,
    projectMFAEnabled,
    projectConfidential,
    isPricingIncluded,
    [FormFields.area]: projectArea,
    [FormFields.region]: projectRegion,
    [FormFields.country]: projectCountry,
  } = formState;

  let projectMFAFlag = projectMFAEnabled.fieldVal === "true";
  // const selectedProjectType = projectTypes
  //   ? projectTypes.find((obj) => obj.id === projectType.fieldVal)
  //   : undefined;
  // const projectDetailsData = {
  //   title: t.project_details_title,
  //   description: projectDesc.fieldVal,
  //   projectType: selectedProjectType ? selectedProjectType.name : undefined,
  //   projectNoOfUsers: noOfUsers,
  //   projectApps,
  // };
  // eslint-disable-next-line
  const typeahead = useRef(null);
  const usersTypeahead = useRef(null);
  const [showPaceInfo, setShowPaceInfo] = useState(false);
  const [isInvalidPaceId, setIsInvalidPaceId] = useState(false);
  const [isPaceFocus, setIsPaceFocus] = useState(false);
  let pageNumber = 1;
  let usersPageNumber = 1;
  const PACE_CHAR_LIMIT = 25;

  const validatePaceID = useCallback(() => {
    const { isRequired, searchQuery, searchLoader, isvalid } = paceFieldState;
    if (
      isRequired &&
      (searchQuery.trim().length || !isvalid) &&
      !searchLoader
    ) {
      setIsInvalidPaceId(true);
      setShowPaceInfo(true);
    } else {
      setIsInvalidPaceId(false);
      setShowPaceInfo(false);
    }
  }, [paceFieldState]);

  useEffect(() => {
    if (
      paceFieldState.searchLoader ||
      (isPaceFocus && paceFieldState.searchProjectsData.length)
    ) {
      setShowPaceInfo(false);
      setIsInvalidPaceId(false);
    } else if (!isPaceFocus || !paceFieldState.isvalid) {
      validatePaceID();
    } else if (!paceFieldState.searchProjectsData.length) {
      setShowPaceInfo(true);
      setIsInvalidPaceId(true);
    }
  }, [
    paceFieldState.searchLoader,
    paceFieldState.isvalid,
    isPaceFocus,
    paceFieldState.searchProjectsData.length,
    validatePaceID,
  ]);

  useEffect(() => {
    setShowPaceInfo(false);
    setIsInvalidPaceId(false);
  }, [formState.projectCategory.key]);
  const focusInTypeAheadSearch = () => {
    if (typeahead.current) {
      // @ts-ignore:
      typeahead.current?.focus();
    }
  };
  useEffect(() => {
    if (paceFieldState.setFocus) {
      focusInTypeAheadSearch();
      paceFieldState.setFocus = false;
    }
    // eslint-disable-next-line no-autofix/react-hooks/exhaustive-deps
  }, [paceFieldState, paceFieldState.setFocus]);

  const renderInput = (
    label: string,
    fieldVal: string,
    fieldName: string,
    isvalid: boolean,
    charLimit,
    disable: boolean,
    addMargin: boolean,
    tooltip?: ReactNode
  ) => (
    <div
      className={`project-form-field ${
        fieldVal.trim().length && isvalid ? validatedInputClass : ""
      } ${fieldVal.trim().length === 0 ? "required" : ""} ${
        addMargin ? "m-r-35" : ""
      } `}
    >
      <span className="input-label">{label}</span>
      <MotifAppInput
        value={fieldVal}
        name={fieldName}
        maxLength={charLimit}
        disabled={disable}
        isValid={
          isvalid &&
          fieldVal.trim().length >= 0 &&
          !validateFormInputForSpecialChar(fieldVal)
        }
        onChange={(e) => onFormChange(e.target.value, e.target.name)}
        endAdornment={tooltip}
      />
      {validateFormInputForSpecialChar(fieldVal) ? (
        <AppFormErrorText>
          {t.all_ssp_validate_special_character_in_text_error.replace(
            "{1}",
            fieldVal.trim().split("")[0]
          )}
        </AppFormErrorText>
      ) : (
        !isvalid &&
        fieldVal.trim().length <= 2 && (
          <AppFormErrorText>
            {fieldName === formState.projectName.fieldName
              ? t.three_chars_required
              : t.required_field}
          </AppFormErrorText>
        )
      )}
    </div>
  );

  const renderSelect = (
    label: string,
    fieldVal: string | undefined,
    fieldName: string,
    isvalid: boolean,
    options: lookupModel[],
    disable: boolean,
    addMargin = false,
    withDescription?: boolean,
    tooltip?: ReactNode
  ) => (
    <div
      className={`project-form-field ${addMargin && "m-r-35"} ${
        fieldVal && isvalid ? validatedInputClass : ""
      }`}
    >
      <MotifAppSelect
        id={fieldName}
        value={fieldVal}
        disabled={disable}
        name={fieldName}
        label={label}
        isValid={isvalid}
        options={getOptions(options, withDescription)}
        onChange={(e) => onFormChange(e.target.value, fieldName)}
        endAdornment={tooltip}
        showWarning={
          fieldName === t.projects_service_line &&
          (projectServiceLine.fieldVal !== userServiceLineId ||
            !userServiceLineId)
        }
      />

      {!isvalid && <AppFormErrorText>{t.required_field}</AppFormErrorText>}
      {fieldName === t.projects_service_line && (
        <AppFormErrorText className="serviceline-warning">
          {getServiceLineWarningTooltipText()}
        </AppFormErrorText>
      )}
    </div>
  );

  const updateMFACheckBox = () => {
    if (!projectMFAEnabled.disable) {
      projectMFAFlag = !projectMFAFlag;
      onFormChange(projectMFAFlag.toString(), projectMFAEnabled.fieldName);
    }
  };

  const getLabelKey = (option) => {
    const projectName =
      option.projectName.length > PACE_CHAR_LIMIT
        ? `${option.projectName.slice(0, PACE_CHAR_LIMIT - 1)}...`
        : option.projectName;

    return `${option.paceId} ${
      option.projectName.length ? `(${projectName})` : `(${t.no_project_name})`
    } `;
  };

  const onPaceChange = (selected) => {
    paceFieldState.handleProjectSelection(selected);
    onPaceFocusOut();
  };

  const onPaceFocusOut = () => {
    setIsPaceFocus(false);
    validatePaceID();
  };

  const togglePaceIdInfoBox = (value) => {
    setShowPaceInfo(value);
  };

  const renderSearch = () => {
    const {
      searchLoader,
      searchProjectsData,
      disable,
      isRequired,
      isvalid,
      selectedValue,
      searchQuery,
    } = paceFieldState;

    if (
      selectedValue?.length &&
      searchProjectsData.length &&
      selectedValue[0].paceId !== "" &&
      selectedValue[0].projectName === ""
    ) {
      selectedValue[0].projectName = searchProjectsData[0].projectName;
    }

    if (
      !selectedValue?.length &&
      !searchQuery.trim().length &&
      typeahead.current
    ) {
      // @ts-ignore:
      typeahead.current?.clear();
    }

    if (
      !approverFieldState.selectedValue?.length &&
      !approverFieldState.searchQuery.trim().length &&
      usersTypeahead.current
    ) {
      // @ts-ignore:
      usersTypeahead.current?.clear();
    }

    // const approver =
    //   approverFieldState.selectedValue?.length &&
    //   approverFieldState.selectedValue[0].mail;
    return (
      <div className="search-wrapper">
        {approverFieldState.canDisplay ? (
          <>
            <div
              className={`project-form-field render-search m-b-4 ${
                !approverFieldState.isRequired ? validatedInputClass : ""
              }`}
            >
              <span className="input-label ">
                {approverFieldState.canDisplay
                  ? t.ppedd_approver
                  : t.pace_pped_title}
              </span>
              <AppIcon icon={search} />
              <AsyncTypeahead
                id="async-approver-search"
                isLoading={approverFieldState.searchUsersLoader}
                filterBy={["displayName"]}
                labelKey={(option) => getApproverLabelKey(option)}
                minLength={3}
                onSearch={(query) => searchUserHandler(query)}
                options={approverFieldState.searchUsersData || []}
                onChange={(selected) =>
                  approverFieldState.handleUsersSelection(selected)
                }
                disabled={false}
                placeholder={
                  approverFieldState.canDisplay ? "" : t.not_applicable
                }
                ref={usersTypeahead}
                useCache={false}
                maxResults={50 * pageNumber}
                paginate={true}
                onPaginate={(e) => paginateSearchUsersHandler(e)}
                paginationText={t.show_more}
                selected={approverFieldState.selectedValue}
                defaultSelected={approverFieldState.selectedValue}
                inputProps={{
                  className: `input-search ${
                    !approverFieldState.isvalid ? "invalid-input" : ""
                  }`,
                }}
                renderMenuItemChildren={(option, props) =>
                  renderApproverMenuItem(option, props)
                }
              >
                {({ onClear, selected }) => {
                  const clearHandler = () => {
                    onClear();
                    approverFieldState.selectedValue?.splice(0, 1);
                  };
                  return (
                    <div className="rbt-aux">
                      {!!selected.length && (
                        <ClearButton onClick={clearHandler} />
                      )}
                    </div>
                  );
                }}
              </AsyncTypeahead>

              {!approverFieldState.isvalid && (
                <AppFormErrorText>{t.required_field}</AppFormErrorText>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={`project-form-field render-search m-b-4 ${
                disable ? "disable-search" : ""
              } ${!isRequired ? validatedInputClass : ""}`}
            >
              <span className="input-label ">
                {disable ? t.pace_pped_title : t.project_pace_id}
              </span>
              {!disable && <AppIcon icon={search} />}
              <AsyncTypeahead
                id="async-search"
                isLoading={searchLoader}
                filterBy={["projectName"]}
                labelKey={(option) => getLabelKey(option)}
                minLength={3}
                onSearch={(query) => searchHandler(query)}
                onBlur={onPaceFocusOut}
                options={searchProjectsData}
                onChange={(selected) => {
                  onPaceChange(selected);
                }}
                placeholder={disable ? t.not_applicable : ""}
                ref={typeahead}
                useCache={false}
                maxResults={50 * pageNumber}
                paginate={true}
                onPaginate={(e) => paginateHandler(e)}
                paginationText={t.show_more}
                disabled={disable}
                selected={disable ? [] : selectedValue}
                defaultSelected={disable ? [] : selectedValue}
                inputProps={{
                  className: `input-search ${disable ? "disable-input" : ""} ${
                    !isvalid ? "invalid-input" : ""
                  }`,
                }}
                renderMenuItemChildren={(option, props) =>
                  renderMenuItem(option, props)
                }
              >
                {({ onClear, selected }) => {
                  const clearHandler = () => {
                    onClear();
                    selectedValue?.splice(0, 1);
                  };
                  return (
                    <div className="rbt-aux">
                      {!!selected.length && (
                        <ClearButton
                          className={disable ? "disable-clear" : ""}
                          onClick={clearHandler}
                        />
                      )}
                    </div>
                  );
                }}
              </AsyncTypeahead>
              {/* <AppTooltip
                placement="right"
                hide={
                  disable ||
                  !selectedValue?.length ||
                  !selectedValue[0].projectName.length
                }
                title={
                  (selectedValue?.length && selectedValue[0].projectName) || ""
                }
              ></AppTooltip> */}

              {!isvalid && (
                <AppFormErrorText>{t.required_field}</AppFormErrorText>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderMenuItem = (option, props) => {
    return (
      <div className="async-dropdown">
        <div className="async-dropdown-name">{`${option.paceId} (${
          option.projectName.length ? option.projectName : t.no_project_name
        })`}</div>
      </div>
    );
  };

  const searchHandler = (query) => {
    paceFieldState.handleSearchChange(query);
    setIsPaceFocus(true);
  };

  const paginateHandler = (e) => {
    const { handleSearchChange, searchQuery } = paceFieldState;

    pageNumber++;
    handleSearchChange(searchQuery, pageNumber);
  };

  const searchUserHandler = (query) => {
    approverFieldState.handleSearchUsersChange(query);
  };

  const paginateSearchUsersHandler = (e) => {
    const { handleSearchUsersChange, searchQuery } = approverFieldState;
    usersPageNumber++;
    handleSearchUsersChange(
      searchQuery,
      approverFieldState.skipToken,
      usersPageNumber
    );
  };

  const getApproverLabelKey = (option) => {
    return option.displayName;
  };

  const renderApproverMenuItem = (option, props) => {
    return (
      <div className="async-dropdown">
        <div className="async-dropdown-name">{option.displayName}</div>
        <div className="async-dropdown-name">{option.mail}</div>
      </div>
    );
  };

  const renderProjectPolicy = (projectCategoryId) => {
    const selectedCategory = projectCategories.find(
      (c) => c.id === projectCategoryId
    );

    if (selectedCategory) {
      return selectedCategory.expirationPolicyText || "";
    }
    return "";
  };

  const confidentailClientOptions: lookupModel[] = [
    { id: "Yes", name: "Yes", key: "Yes", description: "" },
    { id: "No", name: "No", key: "No", description: "" },
  ];

  const getProjectNameTooltip = () =>
    projectName.disable
      ? t.project_name_disable_tooltip
      : t.create_project_project_name_tooltip;

  const getDataLocationTooltipText = () => {
    const apacUsKey = "AU-US";

    return (
      <div>
        {dataLocations.map((d) => (
          <div key={d.name}>{`${d.name}${d.key === apacUsKey ? "*" : ""} ${
            d.description
          }`}</div>
        ))}
        <div className="data-location-tooltip-note">
          {t.create_project_data_location_tooltip_txt}
        </div>
      </div>
    );
  };

  const getServiceLineWarningTooltipText = () => {
    if (
      projectServiceLine.fieldVal !== userServiceLineId ||
      !userServiceLineId
    ) {
      for (const serviceLineItem of serviceLines) {
        if (serviceLineItem.id === userServiceLineId) {
          return `${t.confirm_different_service_line_tooltip} ${""} (${
            serviceLineItem.name
          })`;
        }
      }
    }

    return "";
  };

  const onUpdateOfPrice = useCallback(
    ({ price }) => {
      onFormChange(price ? price === "Yes" : price, "isPricingIncluded");
    },
    [onFormChange]
  );

  const navigateToHelpDesk = (event) => {
    window.open(`${process.env.REACT_APP_HELP_CAPITAL_EDGE_URL}`, "_blank");
  };

  return (
    <div className="project-form-new">
      {allLookupdataLoaded ? (
        <div className="form-wrapper">
          {isInvalidPaceId && showPaceInfo && (
            <div className="pace-id-info-box">
              <AppIcon icon={warning} className="info-box-icon" />
              <div>
                <h5>{t.pace_id_info_box_title}</h5>
                <div className="info-description">
                  <div>{t.pace_id_info_box_description_line1}</div>
                  <div className="info_desc_line_2">
                    {t.pace_id_info_box_description_line2}
                  </div>
                  <div>
                    <label
                      className="info-box-link"
                      onClick={navigateToHelpDesk}
                    >
                      <p>{t.paceid_link_message_text}</p>

                      <AppIcon
                        className="info-box-arrow"
                        icon={arrowRight}
                      ></AppIcon>
                    </label>
                  </div>
                </div>
              </div>
              <div className="pace-id-info-box-close" title={t.Close}>
                <AppIcon
                  icon={cross}
                  className="pace-info-close-btn"
                  onClick={() => togglePaceIdInfoBox(false)}
                />
              </div>
            </div>
          )}
          <div className="enter-details">{t.enter_details}</div>
          <div className="input-row m-b-4">
            {renderSelect(
              t.project_project_category,
              projectCategory.fieldVal,
              projectCategory.fieldName,
              projectCategory.isvalid,
              projectCategories,
              projectCategory.disable,
              true
            )}
            {renderSearch()}
            <span className="data-location-tooltip search-tooltip">
              {!isInvalidPaceId &&
                projectCategory.key === EProjectCategoryKey.CLIENG && (
                  <AppTooltip
                    placement="top"
                    title={t.pace_project_not_found_text}
                  >
                    <AppIcon icon={info} />
                  </AppTooltip>
                )}
              {isInvalidPaceId &&
                projectCategory.key === EProjectCategoryKey.CLIENG && (
                  <div
                    className="paceid-warning-icon"
                    onClick={() => togglePaceIdInfoBox(true)}
                  >
                    <AppTooltip
                      placement="top"
                      title={t.pace_id_warning_icon_tooltip_message}
                    >
                      <AppIcon icon={warning} className="in-valid" />
                    </AppTooltip>
                  </div>
                )}
            </span>
            {renderInput(
              t.project_name,
              projectName.fieldVal,
              projectName.fieldName,
              projectName.isvalid,
              projectName.charLimit,
              projectName.disable,
              false,

              <AppTooltip placement="top" title={getProjectNameTooltip()}>
                {projectName.isvalid ? (
                  <AppIcon icon={info} />
                ) : (
                  <AppIcon icon={exclamation} />
                )}
              </AppTooltip>
            )}
          </div>
          <div className="input-row m-b-4">
            {renderSelect(
              t.project_confidential_client,
              projectConfidential.fieldVal,
              projectConfidential.fieldName,
              projectConfidential.isvalid,
              confidentailClientOptions,
              projectConfidential.disable,
              true
            )}
            {renderInput(
              t.project_client_name,
              projectClientName.fieldVal,
              projectClientName.fieldName,
              projectClientName.isvalid,
              projectClientName.charLimit,
              projectClientName.disable,
              true
            )}
            {renderSelect(
              t.project_client_size,
              projectSize.fieldVal,
              projectSize.fieldName,
              projectSize.isvalid,
              clientSizes,
              projectSize.disable,
              false
            )}
          </div>
          {/* country code fields */}
          <div className="input-row m-b-4">
            <CountryCodeFields
              area={projectArea}
              region={projectRegion}
              country={projectCountry}
              areas={projectAreas}
              regions={projectRegions}
              countries={projectCountries}
              onUpdate={onFormChange}
            />
          </div>
          <div className="input-row m-b-4">
            <SectorSelectComponent
              sector={projectSector}
              subSector={projectSubSector}
              subSubSector={projectSubSubSector}
              sectors={sectors}
              subSectors={subSectors}
              subSubSectors={subSubSectors}
              onUpdate={onFormChange}
            />
          </div>
          <div className="input-row m-b-4">
            {renderSelect(
              t.project_type,
              projectType.fieldVal,
              projectType.fieldName,
              projectType.isvalid,
              projectTypes,
              projectType.disable,
              true
            )}
            {/* {renderSelect(
              t.project_sector,
              projectSector.fieldVal,
              projectSector.fieldName,
              projectSector.isvalid,
              sectors,
              projectSector.disable,
              true
            )} */}
            {renderSelect(
              t.project_service_line,
              projectServiceLine.fieldVal,
              projectServiceLine.fieldName,
              projectServiceLine.isvalid,
              serviceLines,
              projectServiceLine.disable,
              true,
              false,
              <>
                {projectServiceLine.fieldVal === userServiceLineId ||
                !userServiceLineId ? (
                  <AppTooltip placement="top" title={t.service_line_tooltip}>
                    <AppIcon icon={info} />
                  </AppTooltip>
                ) : (
                  <div className="warning-container">
                    <AppIcon icon={warning} />
                  </div>
                )}
              </>
            )}
            {renderSelect(
              t.project_data_hosting_location,
              projectLocation.fieldVal,
              projectLocation.fieldName,
              projectLocation.isvalid,
              dataLocations,
              projectLocation.disable,
              false,
              true,

              <AppTooltip placement="top" title={getDataLocationTooltipText()}>
                {projectLocation.isvalid ? (
                  <AppIcon icon={info} />
                ) : (
                  <AppIcon icon={exclamation} />
                )}
              </AppTooltip>
            )}
          </div>

          <div className="input-row m-b-4 m-r-35">
            <div className="text-area-field">
              <div className={"width-100-percent"}>
                <MotifAppTextArea
                  maxLength={4000}
                  name={projectDesc.fieldName}
                  value={projectDesc.fieldVal}
                  disabled={projectDesc.disable}
                  label={t.project_desc}
                  rows={4}
                  placeholder={t.create_project_textarea_placeholder}
                  isValid={
                    projectDesc.isvalid &&
                    !validateFormInputForSpecialChar(projectDesc.fieldVal)
                  }
                  onChange={(e) => onFormChange(e.target.value, e.target.name)}
                  tooltip={
                    <span className="mfa-tool-tip">
                      <AppTooltip placement="top" title={t.description_tooltip}>
                        <AppIcon icon={info} />
                      </AppTooltip>
                    </span>
                  }
                />
                {!projectDesc.isvalid ? (
                  <AppFormErrorText>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: t.donotdelete_tag_forbidden,
                      }}
                    />
                  </AppFormErrorText>
                ) : validateFormInputForSpecialChar(projectDesc.fieldVal) ? (
                  <AppFormErrorText>
                    {t.all_ssp_validate_special_character_in_text_error.replace(
                      "{1}",
                      projectDesc.fieldVal.trim().split("")[0]
                    )}
                  </AppFormErrorText>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="input-row middle m-r-35">
            <div className="project-form-field mfa-check-box text-area-field">
              <div onClick={updateMFACheckBox} className="checbox-mfa-div">
                <AppCheckbox
                  name={projectMFAEnabled.fieldName}
                  value={projectMFAEnabled.fieldVal}
                  disabled={projectMFAEnabled.disable}
                  checked={projectMFAFlag}
                  children={t.project_mfa}
                />
                <span className="mfa-tool-tip">
                  <AppTooltip
                    placement="top"
                    title={
                      projectMFAEnabled.fieldVal! && projectMFAEnabled.disable
                        ? t.mfa_notSelected_edit_tooltip_text
                        : t.mfa_tooltip_text
                    }
                  >
                    <AppIcon icon={info} />
                  </AppTooltip>
                </span>
              </div>
            </div>
          </div>
          {!!(projectCategory.fieldVal && projectCategory.fieldVal.length) && (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<AppIcon icon={downArrow}></AppIcon>}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{t.project_policy_title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="policy-text">
                  {projectCategory.fieldVal === EProjectCategory.CLI_ENG ? (
                    <PricingProvisionPolicy
                      valid={isPricingIncluded.isvalid}
                      value={{
                        price:
                          isPricingIncluded.fieldVal === true
                            ? "Yes"
                            : isPricingIncluded.fieldVal === false
                            ? "No"
                            : undefined,
                      }}
                      onUpdate={onUpdateOfPrice}
                    />
                  ) : (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: renderProjectPolicy(projectCategory.fieldVal),
                      }}
                    ></span>
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}
          <div className="input-row">
            <div className="policy-text-wrapper"></div>
          </div>
        </div>
      ) : (
        <div className="form-wrapper"></div>
      )}
    </div>
  );
};

export default ProjectForm;
