import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { APP_STATE } from "../../store/reducers";
import { Notifications } from "@material-ui/icons";
import { NOTIFICATION } from "../../store/actions/admin-portal-communication-actions";
import { bell, xMark, chevronCircleDown } from "../../theme/icons";
import { AppTooltip, AppIcon, AppIconButton } from "../../theme";
import themeConfig from "../../theme/config";
import bellCircle from "../../assets/images/icons/bell_circle.svg";
import documentGear from "../../assets/images/icons/document_gear.svg";
import document from "../../assets/images/icons/document.svg";
import openBook from "../../assets/images/icons/open_book.svg";
import statusOnline from "../../assets/images/icons/status_online.svg";
import t from "../../localization/en/translation.json";
import {
  CLOSE_NOTIFICATION_ACTION,
  READ_NOTIFICATION_ACTION,
} from "../../store/actions/me-actions";
import {
  Popover,
  Typography,
  List,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Badge,
} from "@material-ui/core";
import "./notificationStyle.module.scss";

const useStyles = makeStyles((theme) => ({
  popover: {
    padding: theme.spacing(2),
    width: 400,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(1),
    fontFamily: themeConfig.eyInterstateRegular,
  },
  listItem: {
    alignItems: "flex-start",
  },
  listItemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(1),
    marginTop: "-18px",
    width: "18px",
  },

  accordionDetails: {
    flexDirection: "column",
    border: "none",
    padding: "0px 42px 16px",
  },
  accordion: {
    boxShadow: "none",
    border: "none",
    "&:before": {
      display: "none",
    },
  },
  accordionSummary: {
    alignItems: "center",
    fontFamily: themeConfig.eyInterstateRegular,
    paddingLeft: "16px",
  },
  notificationTitle: {
    display: "flex",
    alignItems: "center",
    fontFamily: themeConfig.eyInterstateRegular,
    width: "100%",
    fontSize: themeConfig.fontSize14,
  },
  notificationDescription: {
    fontFamily: themeConfig.eyInterstateLight,
    fontSize: themeConfig.fontSize14,
    marginTop: "-20px",
  },
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  truncate: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontFamily: themeConfig.eyInterstateLight,
    fontSize: themeConfig.fontSize14,
  },
  headerTitle: {
    fontWeight: 400,
  },
  unread: {
    marginLeft: theme.spacing(1),
    width: "8px",
  },
  verticalLine: {
    position: "absolute",
    top: "55%",
    bottom: 0,
    left: "7%",
    borderLeft: `2px solid ${theme.palette.divider}`,
    transform: "translateX(-50%)",
  },
}));

const iconMap = {
  General: document,
  ReleaseNotes: documentGear,
  Survey: bellCircle,
  AdHocNews: openBook,
};

export const NotificationBell = ({ type }) => {
  const notifications = useSelector<APP_STATE, NOTIFICATION[]>(
    ({ meReducer }) => meReducer.activeNotifications
  );

  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [lineCounts, setLineCounts] = useState<{ [key: string]: number }>({});
  const messageRefs = useRef<{ [key: string]: HTMLElement | null }>({});

  const handleBellClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    notifications.forEach((notification) => {
      if (
        messageRefs.current[notification.id as string] &&
        expanded === notification.id
      ) {
        const height = messageRefs.current[notification.id]!.clientHeight;
        const lineHeight = parseInt(
          window.getComputedStyle(messageRefs.current[notification.id]!)
            .lineHeight,
          10
        );
        const lines = Math.ceil(height / lineHeight);
        setLineCounts((prev) => ({
          ...prev,
          [notification.id as string]: lines,
        }));
      }
    });
  }, [expanded, notifications]);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      dispatch(READ_NOTIFICATION_ACTION({ notificationId: panel }));
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="notification-bell">
      <AppIconButton onClick={handleBellClick}>
        <Badge
          badgeContent={
            notifications.filter(
              (notification) => notification?.userNotificationStatus !== "Read"
            )?.length ?? "?"
          }
          color={"secondary"}
        >
          {type === "admin" ? (
            <Notifications style={{ color: "white" }} />
          ) : (
            <AppIcon icon={bell} />
          )}
        </Badge>
      </AppIconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className={classes.popover}>
          <div className={classes.header}>
            <Typography variant="h6" className={classes.headerTitle}>
              {t.user_notification_title}
            </Typography>
            <AppIconButton
              aria-label="close"
              type="button"
              size="small"
              onClick={handleClose}
              className="m-r-20"
            >
              <AppIcon icon={xMark} />
            </AppIconButton>
          </div>
          <List>
            {notifications.map((notification) => {
              const message = notification.message;
              const shouldTruncate = message.length > 250;
              const displayMessage = shouldTruncate
                ? `${message.substring(0, 250)}...`
                : message;

              return (
                <Accordion
                  key={notification.id}
                  className={classes.accordion}
                  expanded={expanded === notification.id}
                  onChange={handleChange(notification.id as string)}
                >
                  <AccordionSummary
                    expandIcon={<AppIcon icon={chevronCircleDown} />}
                    aria-controls={`panel${notification.id}-content`}
                    id={`panel${notification.id}-header`}
                    className={classes.accordionSummary}
                  >
                    <div className={classes.notificationTitle}>
                      <ListItemIcon className={classes.listItemIcon}>
                        <img
                          alt={notification.type}
                          src={iconMap[notification.type]}
                        />
                        <div
                          className={classes.verticalLine}
                          style={{
                            height:
                              expanded === notification.id
                                ? lineCounts[notification.id] === 1
                                  ? "100%"
                                  : `${lineCounts[notification.id] * 40}%`
                                : "50%",
                          }}
                        ></div>
                      </ListItemIcon>
                      <div className={classes.titleWrapper}>
                        <Typography variant="body2">
                          {notification.title}
                          {notification.userNotificationStatus !== "Read" && (
                            <img
                              src={statusOnline}
                              alt={"Unread"}
                              className={classes.unread}
                            />
                          )}
                        </Typography>
                        <Typography className={classes.truncate}>
                          {notification.description}
                        </Typography>
                      </div>
                    </div>
                  </AccordionSummary>

                  <AccordionDetails className={classes.accordionDetails}>
                    <AppTooltip
                      hide={!shouldTruncate}
                      title={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: message,
                          }}
                        ></div>
                      }
                      placement="top"
                    >
                      <Typography
                        ref={(el) =>
                          (messageRefs.current[notification.id as string] = el)
                        }
                        className={classes.notificationDescription}
                        dangerouslySetInnerHTML={{
                          __html: displayMessage,
                        }}
                      ></Typography>
                    </AppTooltip>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default NotificationBell;
